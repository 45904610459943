import React, { useEffect } from 'react';

import { lostPerson } from 'site-react/assets';
import { Link, LinkAsButton } from 'site-react/components/navigation';
import { Curve, Grid, Section, Surface } from 'site-react/components/page';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { Asset, VerticalSpacing } from 'site-react/components/utility';
import analytics from 'site-react/helpers/Analytics/analytics';
import PageLayout from 'site-react/layouts/PageLayout';

import styles from './404.module.css';

const linkContent = {
  fullTime: [
    {
      label: 'Full-time offices in London',
      path: '/office-space-london',
    },
    {
      label: 'Full-time offices in Manchester',
      path: '/office-space-manchester',
    },
    {
      label: 'Full-time offices in Kings Cross',
      path: '/office-space-london/kings-cross',
    },
    {
      label: 'Full-time offices in London Bridge',
      path: '/office-space-london/london-bridge-station',
    },
    {
      label: 'Full-time offices in Shoreditch',
      path: '/office-space-london/shoreditch',
    },
  ],
  onDemand: [
    {
      label: 'On-demand workspace in London',
      path: '/coworking/london',
    },
    {
      label: 'On-demand workspace in Manchester',
      path: '/coworking/manchester',
    },
    {
      label: 'On-demand workspace in Birmingham',
      path: '/coworking/birmingham',
    },
    {
      label: 'On-demand workspace in Bristol',
      path: '/coworking/united-kingdom/bristol',
    },
    {
      label: 'On-demand workspace in Liverpool',
      path: '/coworking/united-kingdom/liverpool',
    },
  ],
  partTime: [
    {
      label: 'Part-time offices in London',
      path: '/office-space-london',
    },
    {
      label: 'Part-time offices in North London',
      path: '/office-space-london/north-london',
    },
    {
      label: 'Part-time offices in East London',
      path: '/office-space-london/east-london',
    },
    {
      label: 'Part-time offices in Central London',
      path: '/office-space-london/central-london',
    },
    {
      label: 'Part-time offices in South London',
      path: '/office-space-london/south-london',
    },
  ],
};

export default function FourOhFour() {
  useEffect(() => {
    analytics.setPageProperties({
      page_category: 'static',
      page_type: '404',
    });

    // NOTE: using analytics.track here, rather than pageTrack, because we want
    // to see this event in Pendo.
    //
    // For pages, we normally set up Pendo tracking by URL, not pageTrack event,
    // because pageTrack doesn't get sent to Pendo. But since an error page URL
    // is inherently unknown for tracking purposes, that approach doesn't work
    // here. By using a track() call, this event will appear in Pendo under its
    // "Track Events" category.
    analytics.track('404 error occurred', {}, { sendPageProperties: true });
  }, []);

  return (
    <PageLayout>
      <Surface backgroundColor="gradient-bluepink">
        <Section verticalPadding="xl">
          <div className={styles['FourOhFour']}>
            <div className={styles['FourOhFour-headerWrapper']}>
              <div className={styles['FourOhFour-headerMobile']}>
                <Heading isCentered level="1" type="title1">
                  Well this is awkward! <br /> We couldn’t find that page.
                </Heading>
              </div>
              <div className={styles['FourOhFour-header']}>
                <Heading level="1" type="hero2">
                  Well this is awkward! <br /> We couldn’t find that page.
                </Heading>
              </div>
              <VerticalSpacing size="sm" />

              <div className={styles['FourOhFour-subHeaderMobile']}>
                <Paragraph isCentered type="content1">
                  Here are some handy links to help you get back on track.
                </Paragraph>
              </div>
              <div className={styles['FourOhFour-subHeader']}>
                <Paragraph type="content1">
                  Here are some handy links to help you get back on track.
                </Paragraph>
              </div>
              <VerticalSpacing size="sm" />

              <LinkAsButton href="/" name="Go to homepage">
                Go to homepage
              </LinkAsButton>
            </div>
            <Asset
              alt="Illustration of a lost person looking at a map"
              className={styles['FourOhFour-image']}
              src={lostPerson}
            />
          </div>
        </Section>
      </Surface>
      <Curve
        bottomColor="white"
        topColor="gradient-pink"
        type="wave-mirrored"
      />
      <Section verticalPadding="xl">
        <Grid breakpoint="md">
          <Grid.Item colSpan="4" justify="start">
            <Heading type="title3">Full-time offices</Heading>
            <VerticalSpacing size="md" />
            <Link
              analyticsMetadata={{
                data_analytics_id: '404-search-offices-clicked',
              }}
              href={{
                pathname: '/office-space-london',
              }}
            >
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Search Offices</b>}
              />
            </Link>
          </Grid.Item>

          <Grid.Item colSpan="4" justify="start">
            <Heading type="title3">Part-time offices</Heading>
            <VerticalSpacing size="md" />
            <ul className={styles['FourOhFour-linkList']}>
              <li>
                <Link
                  analyticsMetadata={{
                    data_analytics_id: '404-search-part-time-offices-clicked',
                  }}
                  href={{
                    pathname: '/office-space-london',
                    query: {
                      isAvailablePartTime: 'true',
                    },
                  }}
                >
                  <TextWithIcon
                    contentType="content1"
                    iconName="chevron_right"
                    iconSize="lg"
                    text={<b>Search Offices</b>}
                  />
                </Link>
              </li>
              <li>
                <Link
                  analyticsMetadata={{
                    data_analytics_id: '404-learn-about-part-time-clicked',
                  }}
                  href="/part-time-offices"
                >
                  <TextWithIcon
                    contentType="content1"
                    iconName="chevron_right"
                    iconSize="lg"
                    text={<b>Learn about part-time offices</b>}
                  />
                </Link>
              </li>
            </ul>
          </Grid.Item>

          <Grid.Item colSpan="4" justify="start">
            <Heading type="title3">On-demand access for teams</Heading>
            <VerticalSpacing size="md" />

            <ul className={styles['FourOhFour-linkList']}>
              <li>
                <Link
                  analyticsMetadata={{
                    data_analytics_id: '404-search-on-demand-clicked',
                  }}
                  href={{
                    pathname: '/coworking/london',
                    query: {
                      productType: 'coworking',
                    },
                  }}
                >
                  <TextWithIcon
                    contentType="content1"
                    iconName="chevron_right"
                    iconSize="lg"
                    text={<b>Search on-demand workspaces</b>}
                  />
                </Link>
              </li>
              <li>
                <Link
                  analyticsMetadata={{
                    data_analytics_id: '404-learn-about-on-demand-clicked',
                  }}
                  href={{
                    pathname: '/pass',
                  }}
                >
                  <TextWithIcon
                    contentType="content1"
                    iconName="chevron_right"
                    iconSize="lg"
                    text={<b>Learn about on-demand for teams</b>}
                  />
                </Link>
              </li>
            </ul>
          </Grid.Item>

          <Grid.Item colSpan="4" justify="start">
            <ul className={styles['FourOhFour-linkList']}>
              {linkContent.fullTime.map(({ label, path }) => (
                <li key={path}>
                  <Link href={{ pathname: path }} styleType="secondary">
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid.Item>

          <Grid.Item colSpan="4" justify="start">
            <ul className={styles['FourOhFour-linkList']}>
              {linkContent.partTime.map(({ label, path }) => (
                <li key={path}>
                  <Link
                    href={{
                      pathname: path,
                      query: {
                        isAvailablePartTime: 'true',
                      },
                    }}
                    styleType="secondary"
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid.Item>

          <Grid.Item colSpan="4" justify="start">
            <ul className={styles['FourOhFour-linkList']}>
              {linkContent.onDemand.map(({ label, path }) => (
                <li key={path}>
                  <Link
                    href={{
                      pathname: path,
                      query: {
                        productType: 'coworking',
                      },
                    }}
                    styleType="secondary"
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid.Item>
        </Grid>
      </Section>
      <Curve bottomColor="neutral-900" topColor="white" type="wave" />
    </PageLayout>
  );
}
